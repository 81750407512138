#dca-title-base1 {
  margin-top: 2rem;
}

#dca-title-base1,
#dca-title-base2 {
  margin-bottom: 1rem;
}

#dca-text-base2,
#dca-textbase1 {
  margin-bottom: 2rem;
}
.fixed-header .m-header {
  position: relative;
}
.sticky-search-top {
  top: 0 !important;
}

.sticky-search-container {
  position: fixed !important;
  top: 0 !important;
}
